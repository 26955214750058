import { TopbarLayout, TOP_BAR_HEIGHT } from "@layouts/topbar"
import { PageBlock } from "@ui/page-block"
import { Heading } from "@ui/heading"
import notFoundImage from "@icons/404.svg"
import Image from "next/image"
import { Headline } from "@ui/headline"
import { Button } from "@ui/button"
import { Card } from "@ui/card"
import Link from "next/link"

export default function NotFound() {
  return (
    <TopbarLayout title="404">
      <PageBlock paddingTop="5">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card paddingTop="5" bg="white" style={{ zIndex: "100" }} maxWidth="fit-content">
            <Heading as="h1" level="1" style={{ textAlign: "center" }}>
              Not found
            </Heading>
            <Headline style={{ textAlign: "center" }}>We couldn't find the page you were looking for.</Headline>
            <Link href="/" passHref>
              <Button variant="secondary" fluid>
                Return to home page
              </Button>
            </Link>
          </Card>
        </div>
        <div
          style={{
            position: "absolute",
            top: TOP_BAR_HEIGHT,
            padding: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: "0.25",
          }}
        >
          <Image
            role="presentation"
            alt=""
            src={notFoundImage.src}
            width={notFoundImage.width * 0.8}
            height={notFoundImage.height * 0.8}
          />
        </div>
      </PageBlock>
    </TopbarLayout>
  )
}
